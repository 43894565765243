import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import ReactDOM from 'react-dom'
import smoothscroll from 'smoothscroll-polyfill'

import { Loading } from 'components/Loaders'
// window.__forceSmoothScrollPolyfill__ = true;

const App = lazy(() => import('./App'))

smoothscroll.polyfill()

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Suspense fallback={<Loading full />}>
        <App />
      </Suspense>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
