import styled from 'styled-components'
import { Colors } from 'core/styles/colors'
import Icon from 'components/Icon'
const DancingSquares = styled.div`
  display: flex;
  justify-content: center;

  .loaders.square-loaders {
    width: 40px;
    height: 40px;
    position: relative;
  }

  .loaders.square-loaders:before,
  .loaders.square-loaders:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    margin: -8px 0 0 -8px;
    width: 16px;
    height: 16px;
    background: #3fb8af;
    animation: da2-1 2s infinite, da2-2 1s infinite;
  }

  .loaders.square-loaders:after {
    background: #ff3d7f;
    animation-delay: -1s, 0s;
  }

  @keyframes da2-1 {
    0% {
      top: 0;
      left: 0;
    }
    25% {
      top: 100%;
      left: 0;
    }
    50% {
      top: 100%;
      left: 100%;
    }
    75% {
      top: 0;
      left: 100%;
    }
    100% {
      top: 0;
      left: 0;
    }
  }

  @keyframes da2-2 {
    40%,
    50% {
      transform: rotate(0.25turn) scale(0.5);
    }
    100% {
      transform: rotate(0.5turn) scale(1);
    }
  }
`

export const SquareLoaders = () => (
  <DancingSquares>
    <div className='loaders square-loaders'></div>
  </DancingSquares>
)

const SpinningCircles = styled.div`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  animation: spin 5s infinite linear;
`

export const NetworkSpinner = () => (
  <SpinningCircles>
    <Icon
      icon='spinner'
      fill={Colors.black}
      height={'2.75rem'}
      width={'3rem'}
    />
  </SpinningCircles>
)

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  &.full {
    height: 100vh;
  }
`

export const Loading = ({ full = false }) => (
  <LoadingWrapper className={full ? 'full' : ''}>
    <SquareLoaders />
  </LoadingWrapper>
)
