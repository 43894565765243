export const Colors = {
  black: '#080a0b',
  blue: '#3772fe',
  darkEggplant: '#250a50cc',
  darkGrey: '#0f1315',
  darkPurple: '#4808AD',
  darkSilver: '#262c34',
  eggplant: '#700ee3',
  gold: '#feca57',
  green: '#44b36b',
  grey: '#798091',
  greyBlack: '#0c0b10',
  lightBlack: '#05050F',
  lightGrey: '#eeeeee',
  lightPurple: '#8854FF',
  mediumGrey: '#14181d',
  mediumSilver: '#3b4450',
  navy: '#1a1e2d',
  orange: '#F16243',
  purple: '#680CFA',
  white: '#f2f4f7',
  pureWhite: '#ffffff',
  red: '#ee5253',
  turquoise: '#48dbfb'
}
