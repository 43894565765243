import { ReactComponent as ArrowDown } from './arrow_down.svg'
import { ReactComponent as ArrowOut } from './arrow_out.svg'
import { ReactComponent as Back } from './back.svg'
import { ReactComponent as BackToTop } from './back_to_top.svg'
import { ReactComponent as Cancel } from './cancel.svg'
import { ReactComponent as Card } from './card.svg'
import { ReactComponent as Copy } from './copy.svg'
import { ReactComponent as Discord } from './discord.svg'
import { ReactComponent as Dot } from './dot.svg'
import { ReactComponent as Edit } from './edit.svg'
import { ReactComponent as Etherscan } from './etherscan.svg'
import { ReactComponent as EyeClosed } from './eye_closed.svg'
import { ReactComponent as EyeOpen } from './eye_open.svg'
import { ReactComponent as Gas } from './gas.svg'
import { ReactComponent as Gear } from './gear.svg'
import { ReactComponent as History } from './history.svg'
import { ReactComponent as Home } from './home.svg'
import { ReactComponent as Invest } from './invest.svg'
import { ReactComponent as Lock } from './lock.svg'
import { ReactComponent as Logo } from './logo.svg'
import { ReactComponent as Menu } from './menu.svg'
import { ReactComponent as OpenSea } from './opensea.svg'
import { ReactComponent as Row } from './row.svg'
import { ReactComponent as Search } from './search.svg'
import { ReactComponent as Spinner } from './spinner.svg'
import { ReactComponent as Star } from './star.svg'
import { ReactComponent as Statue } from './statue.svg'
import { ReactComponent as Swap } from './swap_fill.svg'
import { ReactComponent as Token } from './token.svg'
import { ReactComponent as Trash } from './trash.svg'
import { ReactComponent as Twitter } from './twitter.svg'
import { ReactComponent as Wallet } from './wallet.svg'
import { ReactComponent as Website } from './website.svg'

import { ReactComponent as Arbi } from './arbi.svg'
import { ReactComponent as BSC } from './bsc.svg'
import { ReactComponent as Eth } from './eth.svg'
import { ReactComponent as EthFilled } from './eth_filled.svg'
import { ReactComponent as Matic } from './matic.svg'
import { ReactComponent as One } from './one.svg'

import { IconSVG } from 'types/sentible'

export const enum NETWORK_TYPES {
  arbi = 'arbi',
  bsc = 'bsc',
  eth = 'eth',
  ethFilled = 'ethFilled',
  matic = 'matic',
  one = 'one'
}

export const enum ICON_TYPES {
  arrowDown = 'arrowDown',
  arrowOut = 'arrowOut',
  back = 'back',
  backToTop = 'backToTop',
  cancel = 'cancel',
  card = 'card',
  copy = 'copy',
  discord = 'discord',
  dot = 'dot',
  edit = 'edit',
  etherscan = 'etherscan',
  eyeClosed = 'eyeClosed',
  eyeOpen = 'eyeOpen',
  gas = 'gas',
  gear = 'gear',
  history = 'history',
  home = 'home',
  invest = 'invest',
  lock = 'lock',
  logo = 'logo',
  menu = 'menu',
  opensea = 'opensea',
  row = 'row',
  search = 'search',
  spinner = 'spinner',
  star = 'star',
  statue = 'statue',
  swap = 'swap',
  token = 'token',
  trash = 'trash',
  twitter = 'twitter',
  wallet = 'wallet',
  website = 'website'
}

export type ALL_ICONS = keyof typeof NETWORK_TYPES | keyof typeof ICON_TYPES

export const Icons: Record<ALL_ICONS, IconSVG> = {
  [ICON_TYPES.arrowDown]: ArrowDown,
  [ICON_TYPES.arrowOut]: ArrowOut,
  [ICON_TYPES.back]: Back,
  [ICON_TYPES.backToTop]: BackToTop,
  [ICON_TYPES.cancel]: Cancel,
  [ICON_TYPES.card]: Card,
  [ICON_TYPES.copy]: Copy,
  [ICON_TYPES.discord]: Discord,
  [ICON_TYPES.dot]: Dot,
  [ICON_TYPES.edit]: Edit,
  [ICON_TYPES.etherscan]: Etherscan,
  [ICON_TYPES.eyeClosed]: EyeClosed,
  [ICON_TYPES.eyeOpen]: EyeOpen,
  [ICON_TYPES.gas]: Gas,
  [ICON_TYPES.gear]: Gear,
  [ICON_TYPES.history]: History,
  [ICON_TYPES.home]: Home,
  [ICON_TYPES.invest]: Invest,
  [ICON_TYPES.lock]: Lock,
  [ICON_TYPES.logo]: Logo,
  [ICON_TYPES.menu]: Menu,
  [ICON_TYPES.opensea]: OpenSea,
  [ICON_TYPES.row]: Row,
  [ICON_TYPES.search]: Search,
  [ICON_TYPES.spinner]: Spinner,
  [ICON_TYPES.star]: Star,
  [ICON_TYPES.statue]: Statue,
  [ICON_TYPES.swap]: Swap,
  [ICON_TYPES.token]: Token,
  [ICON_TYPES.trash]: Trash,
  [ICON_TYPES.twitter]: Twitter,
  [ICON_TYPES.wallet]: Wallet,
  [ICON_TYPES.website]: Website,
  // network icons
  [NETWORK_TYPES.arbi]: Arbi,
  [NETWORK_TYPES.bsc]: BSC,
  [NETWORK_TYPES.eth]: Eth,
  [NETWORK_TYPES.ethFilled]: EthFilled,
  [NETWORK_TYPES.matic]: Matic,
  [NETWORK_TYPES.one]: One
}
