import React from 'react'
import { Icons, ALL_ICONS } from 'core/images/icons'
import styled from 'styled-components'

type Props = {
  children?: React.SVGProps<SVGTextElement>
  className?: string
  fill?: CSSStyleDeclaration['color']
  fillPath?: CSSStyleDeclaration['color']
  height?: string | number
  icon: ALL_ICONS
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  strokeWidth?: number | string
  title?: string
  width?: string | number
}

const StyledIcon = styled.span<{ fillPath?: string }>`
  display: inline-block;

  > svg {
    path:first-of-type {
      ${({ fillPath }) => `fill: ${fillPath}`};
    }
  }
`

const Icon: React.FC<Props> = ({
  className = '',
  fill,
  fillPath,
  height = '1.75rem',
  icon,
  onClick,
  children,
  strokeWidth,
  title,
  width = '1.75rem'
}) => {
  const SVG = Icons[icon]
  const _className = `icon icon-${icon} ${className}`
  return (
    <StyledIcon
      onClick={onClick}
      fillPath={fillPath}
      className={_className}
      title={title}
    >
      <SVG
        fill={fill}
        height={height}
        stroke={fill}
        strokeWidth={strokeWidth}
        width={width}
      />
      {children}
    </StyledIcon>
  )
}

export default Icon
